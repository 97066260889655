<!-- Desktop View -->
<div class="advertisment-container py-lg-5 d-none d-lg-flex">
  <p class="advertisment-header col-xl-60 col-lg-55 ps-2 ps-lg-3 ps-xl-0">
    {{ 'advertisement.header' | translate }}
  </p>
  <div class="row">
    <div class="d-flex justify-content-between">
      <img src="/assets/icons/device.png" srcset="/assets/icons/device.png 1x, /assets/icons/device_2x.png 2x"
      class="advertisment-mobile-image">
    </div>
  </div>
  <div class="advertisment-content col-120 p-lg-5 py-md-4 mt-lg-2 mt-md-0">
    <div class="row">
      <div class="col-120 col-xl-60 col-lg-55">
          <h1 class="advertisement.content.header">{{ 'advertisement.content.header' | translate }}</h1>
          <p>{{ 'advertisement.content.p1' | translate }}</p>
          <p>{{ 'advertisement.content.p2' | translate }}</p>
      </div>
      <div class="col-120 col-xl-30 col-lg-35 offset-lg-30">
        <div class="text-end">
          <a *ngIf="!('hide.hgapp.apple' | feature)" href="{{'advertisement.link.appstore.ios' | translate}}" target="_blank">
            <img src="/assets/icons/appstore.png" alt="App Store" class="py-3">
          </a>
          <a *ngIf="!('hide.hgapp.google' | feature)" href="{{'advertisement.link.appstore.android' | translate}}" target="_blank">
            <img src="/assets/icons/googleplay.png" alt="Google Play">
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Mobile View -->
<div class="advertisment-container pt-4 d-lg-none d-block">
  <p class="advertisment-header">{{ 'advertisement.header' | translate }}</p>
  <div class="mt-3">
    <a href="{{ corporateUrl }}/{{ countryCode }}" target="_blank">
      <div class="d-flex justify-content-center">
        <img src="/assets/icons/devices_mobile.png"
          srcset="/assets/icons/devices_mobile.png 1x, /assets/icons/devices_mobile_2x.png 2x"
          class="advertisment-mobile-image">
      </div>
    </a>
    <div class="advertisment-content col-120 mt-5">
        <div class="container">
          <h1 class="advertisement.content.header">{{ 'advertisement.content.header' | translate }}</h1>
          <p class="advertisment-content-text">{{ 'advertisement.content.p1' | translate }}</p>
          <p class="advertisment-content-text">{{ 'advertisement.content.p2' | translate }}</p>
        </div>
      <div class="row">
        <div class="advertisment-content-appstore justify-content-center">
          <div class="advertisment-app-store">
            <a *ngIf="!('hide.hgapp.apple' | feature)" href="{{'advertisement.link.appstore.ios' | translate}}" target="_blank">
              <img src="/assets/icons/appstore.png" alt="App Store" />
            </a>
          </div>
          <div class="advertisment-app-store">
            <a *ngIf="!('hide.hgapp.google' | feature)" href="{{'advertisement.link.appstore.android' | translate}}" target="_blank">
              <img src="/assets/icons/googleplay.png" alt="Google Play" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
