import { Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  isocode:string = 'de-DE';

  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe((params) => {
      this.isocode = params['isocode'];
    });
  }

  transform(value: any): any {
    let date = new Date(value);
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
    };
    const formatteddate = date.toLocaleDateString(this.isocode, options);
    return formatteddate;
  }
}
