<div class="row row-cols-1 row-cols-lg-3 g-3 align-items-center">
  <div *ngIf="'feature.profile' | feature">
    <a
      type="button"
      routerLink="profile"
      tabindex="1"
      (keyup.enter)="changePath('profile')"
      class="row text-decoration-none cursor-pointer hover-background-grey border border-2 border-dark border-opacity-25"
    >
      <app-navigation-element
        image="/assets/icons/user.svg"
        class="d-flex justify-content-center"
        [header]="'header.profile' | translate"
        [text]="'header.text.profile' | translate"
      />
    </a>
  </div>
  <div *ngIf="'feature.address' | feature">
    <a
      type="button"
      routerLink="address"
      tabindex="1"
      (keyup.enter)="changePath('address')"
      class="row text-decoration-none cursor-pointer hover-background-grey border border-2 border-dark border-opacity-25"
    >
      <app-navigation-element
        image="/assets/icons/home.svg"
        class="d-flex justify-content-center"
        [header]="'header.address' | translate"
        [text]="'header.text.address' | translate"
      />
    </a>
  </div>
  <div *ngIf="'feature.orders' | feature">
    <a
      type="button"
      routerLink="orders"
      tabindex="1"
      (keyup.enter)="changePath('orders')"
      class="row text-decoration-none cursor-pointer hover-background-grey border border-2 border-dark border-opacity-25"
    >
      <app-navigation-element
        image="/assets/icons/order.svg"
        class="d-flex justify-content-center"
        [header]="'header.orders' | translate"
        [text]="'header.text.orders' | translate"
      />
    </a>
  </div>
  <div *ngIf="'feature.service' | feature">
    <a
      type="button"
      routerLink="service"
      tabindex="1"
      (keyup.enter)="changePath('service')"
      class="row text-decoration-none cursor-pointer hover-background-grey border border-2 border-dark border-opacity-25"
    >
      <app-navigation-element
        image="/assets/icons/service.svg"
        class="d-flex justify-content-center"
        [header]="'header.service' | translate"
        [text]="'header.text.service' | translate"
      />
    </a>
  </div>
  <div *ngIf="('feature.contact' | feature) && hasTranslation(linkContact)">
    <a
      type="button"
      tabindex="1"
      href="{{ linkContact | translate }}"
      target="_blank"
      class="row text-decoration-none cursor-pointer hover-background-grey border border-2 border-dark border-opacity-25"
    >
      <app-navigation-element
        image="/assets/icons/message.svg"
        class="d-flex justify-content-center"
        [header]="'header.contact' | translate"
        [text]="'header.text.contact' | translate"
      />
    </a>
  </div>
  <div
    *ngIf="('feature.newsletter' | feature) && hasTranslation(linkNewsLetter)"
  >
    <a
      type="button"
      tabindex="1"
      href="{{ linkNewsLetter | translate }}"
      target="_blank"
      class="row text-decoration-none cursor-pointer hover-background-grey border border-2 border-dark border-opacity-25"
    >
      <app-navigation-element
        image="/assets/icons/newsletter.svg"
        class="d-flex justify-content-center"
        [header]="'header.newsletter' | translate"
        [text]="'header.text.newsletter' | translate"
      />
    </a>
  </div>
</div>
