import { AfterContentInit, Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouterParamService } from 'src/app/shared/params/params.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class NavigationComponent implements AfterContentInit {
  isocode: string = '';
  isAuthenticated = false;

  constructor(public params: RouterParamService) {}
  ngAfterContentInit() {
    this.isocode = this.params.paramsSnapshot()['isocode'];
  }
}
