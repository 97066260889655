import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RecommendationService {
  backendUrl: string;
  constructor(
    public oidcSecurityService: OidcSecurityService,
    private http: HttpClient
  ) {
    this.backendUrl = environment.backendURL;
  }
  async getRecommendations(isocode, cluster) {
    //get fresh accessToken
    let accessToken = await lastValueFrom(
      this.oidcSecurityService.getAccessToken()
    );
    let httpHeader = new HttpHeaders().set(
      'Authorization',
      `Bearer ${accessToken}`
    );
    let httpOptions = { headers: httpHeader };
    try {
      let response = await lastValueFrom(
        this.http.get(
          this.backendUrl +
            '/Recommendation?isocode=' +
            isocode +
            '&cluster=' +
            cluster,
          httpOptions
        )
      );
      return response;
    } catch (response) {
      return response;
    }
  }
}
