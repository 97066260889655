import {
  APP_INITIALIZER,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { FeatureService, FeatureServiceConfig } from './feature.service';
import { FeaturePipe } from './feature.pipe';
import { featureFactory } from './feature.factory';
import { HttpClient } from '@angular/common/http';

@NgModule({
  imports: [CommonModule],
  declarations: [FeaturePipe],
  exports: [FeaturePipe],
})
export class FeatureModule {
  constructor(@Optional() @SkipSelf() parentModule?: FeatureModule) {
    if (parentModule) {
    }
  }

  static forRoot(
    config: FeatureServiceConfig
  ): ModuleWithProviders<FeatureModule> {
    return {
      ngModule: FeatureModule,
      providers: [
        { provide: FeatureServiceConfig, useValue: config },
        FeatureService,
        {
          provide: APP_INITIALIZER,
          useFactory: featureFactory,
          deps: [FeatureService, HttpClient],
          multi: true,
        },
      ],
    };
  }
  static forChild(
    config: FeatureServiceConfig
  ): ModuleWithProviders<FeatureModule> {
    return {
      ngModule: FeatureModule,
      providers: [
        { provide: FeatureServiceConfig, useValue: config },
        FeatureService,
        {
          provide: APP_INITIALIZER,
          useFactory: featureFactory,
          deps: [FeatureService, HttpClient],
          multi: true,
        },
      ],
    };
  }
}
