<div class="container-fluid bg-primary info">
  <div class="px-3">
    <div class="container">
      <div *ngIf="skelleton; else elseHeaderBlock">
        <br />
        <br />
        <h2>
          <ngx-skeleton-loader [theme]="{height: '40px',width: '50%'}" appearance="line"></ngx-skeleton-loader>
        </h2>
        <p>
          <ngx-skeleton-loader [theme]="{height: '20px',width: '40%'}" appearance="line"></ngx-skeleton-loader>
        </p>
        <br />
      </div>
      <ng-template #elseHeaderBlock>
        <br />
        <h1>{{ "header." + currentRoute | translate }} {{id}}</h1>
        <p class="mb-2">{{ "header.text." + currentRoute | translate }}</p>
        <br />
      </ng-template>
    </div>
  </div>
</div>
<div class="px-3 pt-2 pt-lg-3">
  <div class="container">
    <div *ngIf="back; else elseBlock">
      <div class="row">
        <div class="cursor-pointer col-5 col-lg-2" (click)="backPath()">
          <img src="/assets/icons/back.svg" alt="backarrow" />
        </div>
        <div class="col-110 col-lg-60">
          <p>
            <a class="cursor-pointer back-text" (click)="backPath()">
              {{"header.backto."+ this.backUrl| translate}}
            </a>
          </p>
        </div>
      </div>
    </div>
    <ng-template #elseBlock>
      <br />
    </ng-template>
  </div>
</div>
