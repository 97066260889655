
<div class="col p-lg-3 d-flex flex-column  justify-content-between bg-white mb-3" style="height: 400px" >
  <a class="d-block text-center" href="{{ recommendation.url }}?utm_source=cupo&utm_order={{ recommendation.number }}" target="_blank">
      <img src="{{recommendation.imageUrl}}" style="height: 200px; width: 200px;">
  </a>
  <div class="d-block">
    <p class="mb-1 recommendation-productType">{{recommendation.productType}}</p>
    <p class="mb-0 recommendation-productname">{{recommendation.name}}</p>
  </div>
  <app-currency [amount]="recommendation.price.amount" [isocode]="isocode" ></app-currency>
  <button class="col-120 btn btn-primary" (click)="toUrl()">{{'recommendations.button' | translate}}</button>
</div>

