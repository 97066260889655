import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NgOptimizedImage } from '@angular/common'
@Component({
  selector: 'app-navigation-element',
  templateUrl: './navigation-element.component.html',
  styleUrls: ['./navigation-element.component.css'],
  encapsulation: ViewEncapsulation.None,

})
export class NavigationElementComponent {
  @Input('image') image:string | undefined;
  @Input('header') header:string | undefined;
  @Input('text') text:string | undefined;
}
