import { Pipe, PipeTransform } from '@angular/core';
import { FeatureService } from './feature.service';

@Pipe({
  name: 'feature',
  pure: false,
})
export class FeaturePipe implements PipeTransform {
  constructor(private featureService: FeatureService) {}

  transform(value: string, ...args: any[]): any {
    return this.featureService.activated(value);
  }
}
