import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {
  AbstractSecurityStorage,
  AuthModule,
  DefaultLocalStorageService,
  LogLevel,
} from 'angular-auth-oidc-client';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { CallbackComponent } from './callback/callback.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [UnauthorizedComponent, CallbackComponent],
  imports: [
    NgxSkeletonLoaderModule,
    AuthModule.forRoot({
      config: {
        authority: environment.loginUrl,
        redirectUrl: `${window.location.origin}/callback`,
        postLogoutRedirectUri: 'postLogoutRedirectUriPlaceholder',
        clientId: environment.clientId,
        unauthorizedRoute: `/unauthorized`,
        scope: 'mykaercher-web-api openid',
        responseType: 'code',
        silentRenew: true,
        silentRenewUrl: `${window.location.origin}/app/feature/authoritzation/silent_renew.html`,
        renewTimeBeforeTokenExpiresInSeconds: 10,
        customParamsAuthRequest: {
          client_secret: environment.clientSecret,
        },
        customParamsRefreshTokenRequest: {
          client_secret: environment.clientSecret,
        },
        customParamsCodeRequest: {
          client_secret: environment.clientSecret,
        },
        logLevel: LogLevel.None,
      },
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [CallbackComponent, AuthModule],
  providers: [
    {
      provide: AbstractSecurityStorage,
      useClass: DefaultLocalStorageService,
    },
  ],
  bootstrap: [CallbackComponent],
})
export class AuthConfigModule {}
