import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NumberFormatPipe } from './pipes/number-format.pipe';
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';
import { PartnumberPipe } from './pipes/partnumber.pipe';
import { PercentageFormatPipe } from './pipes/percentage-format.pipe';
import { AddressInfoComponent } from './components/address-info/address-info.component';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { CurrencyComponent } from './components/currency/currency.component';
import { AddressInfoCorrectionComponent } from './components/address-info-correction/address-info-correction.component';

@NgModule({
  declarations: [
    NumberFormatPipe,
    CurrencyFormatPipe,
    PartnumberPipe,
    PercentageFormatPipe,
    AddressInfoComponent,
    AddressInfoCorrectionComponent,
    CurrencyComponent,
    DateFormatPipe,
  ],
  imports: [CommonModule],
  exports: [
    NumberFormatPipe,
    CurrencyFormatPipe,
    AddressInfoComponent,
    AddressInfoCorrectionComponent,
    CurrencyComponent,
    PartnumberPipe,
    DateFormatPipe,
  ],
})
export class SharedModule {}
