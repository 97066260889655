import { loadRemoteModule } from '@angular-architects/module-federation';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  EventTypes,
  LogoutAuthOptions,
  OidcSecurityService,
  PublicEventsService,
} from 'angular-auth-oidc-client';
import { filter, firstValueFrom } from 'rxjs';
import { FeatureService } from 'src/app/shared/modules/feature/feature.service';
import { RouterParamService } from 'src/app/shared/params/params.service';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { FormService } from 'src/app/shared/modules/form/form.service';

declare global {
  interface Window {
    _satellite: any;
  }
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
  isocode: string;
  languageCode: string = 'en';
  countryCode: string = 'GB';
  configFooter: any = {};
  configHead: any = {};
  init: boolean = false;
  authResult: any = {};
  isAuthenticated: boolean = false;
  initLanguages: boolean;
  private doc: Document;
  analyticsUrl = environment.analyticsUrl;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    public oidcSecurityService: OidcSecurityService,
    private eventService: PublicEventsService,
    public params: RouterParamService,
    private translation: TranslateService,
    private feature: FeatureService,
    private form: FormService,
    private location: Location,
    private router: Router,
  ) {}

  ngOnInit() {
    //ValidateCallback
    this.oidcSecurityService
      .checkAuth()
      .subscribe(
        ({ isAuthenticated, userData, accessToken, idToken, configId }) => {
          this.isAuthenticated = isAuthenticated;
          if (isAuthenticated) {
            if (!!sessionStorage.getItem('path')) {
              let path = sessionStorage.getItem('path') || '';
              let pathArray = path.split('/');
              pathArray.shift();
              this.router.navigate(pathArray);
            }
            sessionStorage.removeItem('path');
          }
        },
      );

    this.params.params$.subscribe(async (param) => {
      if (param['isocode'] != undefined && this.isocode != param['isocode']) {
        //Initialize Application
        this.initLanguages = false;
        this.isocode = param['isocode'];
        this.languageCode = this.isocode.split('-')[0];
        this.countryCode = this.isocode.split('-')[1];
        try {
          if (
            this.languageCode != this.languageCode.toLowerCase() ||
            this.countryCode != this.countryCode.toUpperCase()
          ) {
            let redirectPath = this.location
              .path()
              .replace('/' + this.isocode, '');
            window.location.href =
              window.location.origin +
              '/' +
              this.languageCode.toLowerCase() +
              '-' +
              this.countryCode.toUpperCase() +
              redirectPath;
          }
          await firstValueFrom(this.translation.use(this.isocode));
          await this.form.use(this.isocode);
          await this.feature.use(this.countryCode);
          this.initLanguages = true;
        } catch (x) {
          window.location.href = environment.notFoundUrl;
          this.initLanguages = false;
        }
        if (this.initLanguages) {
          let languages: string[] = this.feature.activated('lang');
          if (!languages.includes(this.languageCode)) {
            window.location.href = environment.notFoundUrl;
          }
          //Configure HTML Language
          this.document.documentElement.setAttribute(
            'lang',
            this.isocode.split('-')[0],
          );

          //Configure Header and Footer
          this.configFooter = {
            isocode: this.isocode,
          };

          this.configHead = {
            isocode: this.isocode,
            components: {
              mykaercher: {
                enabled: false,
              },
              wishlist: {
                enabled: false,
              },
              cart: {
                enabled: false,
              },
              search: {
                enabled: false,
              },
              logout: {
                enabled: true,
              },
            },
            metaLinks: [
              {
                label: await firstValueFrom(
                  this.translation.stream('generic.logout'),
                ),
                value: 'logout',
              },
            ],
            metaLinkCallback: (value: string) => {
              if (value == 'logout') {
                this.logout();
              }
            },
            logoutCallback: () => {
              this.logout();
            },
          };

          //Load Header
          loadRemoteModule({
            exposedModule: './Module',
            type: 'module',
            remoteEntry:
              'https://redesign-cms.s3.amazonaws.com/temp/test/header_ng/remoteEntry.js',
          }).then((m) => m.HeadModule);

          //Load Footer
          loadRemoteModule({
            exposedModule: './Module',
            type: 'module',
            remoteEntry:
              'https://redesign-cms.s3.amazonaws.com/temp/test/footer_ng/remoteEntry.js',
          }).then((m) => m.FooterModule);
        }
      }
      await this.checkLogin(param);
      this.init = true;
    });

    this.doc = window.document;

    if (this.analyticsUrl && !this.doc.getElementById('analyticsUrl')) {
      let nodeHead = this.doc.createElement('script');
      nodeHead.src = this.analyticsUrl;
      nodeHead.type = 'text/javascript';
      nodeHead.async = true;
      nodeHead.id = 'analyticsUrl';
      this.doc.getElementsByTagName('head')[0].appendChild(nodeHead);

      nodeHead.addEventListener('load', () => {
        window._satellite.pageBottom();
      });
    }

    if (navigator.userAgent.indexOf('iPhone') > -1) {
      this.doc
        .querySelector('[name=viewport]')
        ?.setAttribute(
          'content',
          'width=device-width, initial-scale=1, maximum-scale=1',
        );
    }
  }

  onActivate(event) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  async checkLogin(param) {
    //Dont Login on callback or redirect
    let tryLogin: boolean = true;
    let urlArray = this.location.path().split('/');
    if (urlArray.length == 2) {
      let currentRoute = urlArray[1].split('?')[0];
      if (currentRoute == 'callback') {
        tryLogin = false;
      }
    }
    if (param['countrycode'] != undefined) {
      tryLogin = false;
      if (param['rockordernumber'] != undefined) {
        this.router.navigate([
          param['countrycode'],
          'service',
          'details',
          param['rockordernumber'],
        ]);
      } else {
        window.location.href =
          window.location.origin + '/registration/' + param['countrycode'];
      }
    }

    if (!this.isAuthenticated && tryLogin) {
      //Check Lang then login
      let kLoginLang = 'en';
      if (this.feature.activated('klogin') != false) {
        let validKLoginLangs: string[] = this.feature.activated('klogin');
        let targetLanguage = this.languageCode;
        if (this.feature.activated('langParser') != false) {
          let langParser = this.feature.activated('langParser');
          let mappedLanguage = langParser.klogin[this.languageCode];
          if (mappedLanguage !== undefined) {
            targetLanguage = mappedLanguage;
          }
        }

        if (validKLoginLangs.includes(targetLanguage)) {
          kLoginLang = targetLanguage;
        }
      }
      this.login(kLoginLang);
    }
  }

  login(languageCode: string) {
    sessionStorage.removeItem('path');
    sessionStorage.setItem('path', this.location.path());
    const authOptions = {
      customParams: {
        ui_locales: languageCode,
      },
    };
    this.oidcSecurityService.authorize('', authOptions);
  }

  logout() {
    const logoutAuthOptions: LogoutAuthOptions = {
      urlHandler: (url) => {
        this.oidcSecurityService.logoffLocal();
        window.location.href = url.replace(
          'postLogoutRedirectUriPlaceholder',
          `${window.location.origin}/registration/${this.isocode}/logout`,
        );
      },
    };
    this.oidcSecurityService
      .logoff('', logoutAuthOptions)
      .subscribe((result) => {});
  }
}
