import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, filter, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RouterParamService {
  params$: Observable<{ [key: string]: string }>;

  constructor(
    router: Router,
    private readonly rootRoute: ActivatedRoute
  ) {
    this.params$ = router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      map((e) => this.getParams(this.rootRoute))
    );
  }
  paramsSnapshot() {
    return this.getParams(this.rootRoute);
  }
  private getParams(route: ActivatedRoute) {
    // route param names (eg /a/:personId) must be ditinct within
    // a route otherwise they'll be overwritten
    let params = route.snapshot.params;
    params = { ...route.snapshot.queryParams, ...params };
    if (route.children) {
      for (let r of route.children) {
        params = { ...this.getParams(r), ...params };
      }
    }
    return params;
  }
}
