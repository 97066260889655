import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-advertisment',
  templateUrl: './advertisement.component.html',
  styleUrls: ['./advertisement.component.css'],
})
export class AdvertisementComponent {
  @Input() isocode: string;
  corporateUrl: string = environment.corporateUrl;
  constructor() {}
  get countryCode(): string {
    if (!this.isocode) return 'int';
    return this.isocode.split('-')?.[1];
  }
}
