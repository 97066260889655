import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './navigation/navigation.component';
import { NavigationElementComponent } from './navigation-element/navigation-element.component';
import { AdvertisementComponent } from './advertisement/advertisement.component';
import { NavigationRoutingModule } from './routing/navigation-routing.module';
import { NavigationContainerComponent } from './navigation-container/navigation-container.component';
import { TranslateModule } from '@ngx-translate/core';
import { FeatureModule } from 'src/app/shared/modules/feature/feature.module';
import { RecommendationComponent } from './recommendation/recommendation.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {
  NgbCarouselModule,
  NgbPaginationModule,
} from '@ng-bootstrap/ng-bootstrap';
import { RecommendationElementComponent } from './recommendation-element/recommendation-element.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [
    NavigationComponent,
    NavigationContainerComponent,
    NavigationElementComponent,
    AdvertisementComponent,
    RecommendationComponent,
    RecommendationElementComponent,
  ],
  imports: [
    CommonModule,
    NavigationRoutingModule,
    TranslateModule.forChild(),
    FeatureModule,
    NgxSkeletonLoaderModule,
    NgbPaginationModule,
    NgbCarouselModule,
    SharedModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [NavigationComponent],
  exports: [NavigationComponent],
})
export class NavigationModule {}
