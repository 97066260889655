

<div class="container px-3 px-xl-0">
    <div class="row row-cols-1 row-cols-lg-3 g-3 align-items-center">
        <ngx-skeleton-loader [theme]="{height: '132px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{height: '132px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{height: '132px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{height: '132px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{height: '132px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{height: '132px'}"></ngx-skeleton-loader>
    </div>
</div>
<div class="container px-lg-0">
    <br/>
    <br/>
    <ngx-skeleton-loader [theme]="{height: '40px',width: '50%'}"></ngx-skeleton-loader>
    <br/>
    <div class="advertisment-content col-120 p-lg-5 py-md-4 mt-lg-3 mt-md-0">
        <div class="row">
            <div class="col-120 col-xl-60 col-lg-55">
                <h2>
                    <ngx-skeleton-loader [theme]="{height: '40px'}" appearance="line"></ngx-skeleton-loader>
                </h2>
                <p>
                    <ngx-skeleton-loader appearance="line"></ngx-skeleton-loader>
                    <ngx-skeleton-loader appearance="line"></ngx-skeleton-loader>
                </p>
                <p>
                    <ngx-skeleton-loader appearance="line"></ngx-skeleton-loader>
                    <ngx-skeleton-loader appearance="line"></ngx-skeleton-loader>
                    <ngx-skeleton-loader appearance="line"></ngx-skeleton-loader>

                </p>
            </div>
        </div>
    </div>
</div>


<div class="item">
</div>