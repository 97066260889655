import { Pipe, PipeTransform } from '@angular/core';
import { NumberFormat } from '../models/number-format.model';

@Pipe({
  name: 'numberFormat',
})
export class NumberFormatPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return this.formatNumber(value as number, args as NumberFormat);
  }

  private formatNumber(amount: number, numberFormat: NumberFormat): string {
    let amountDisplayed = amount.toString();
    amountDisplayed = this.formatDecimalDigits(amountDisplayed, numberFormat);
    amountDisplayed = this.formatDecimalDelimiter(amountDisplayed, numberFormat);
    amountDisplayed = this.formatThousandDelimiter(amountDisplayed, numberFormat);

    return amountDisplayed;
  }

  private formatThousandDelimiter(amount: string, numberFormat: NumberFormat): string {
    const indexOfOriginalDelimiter = amount.indexOf(numberFormat.decimalDelimiter);
    if (indexOfOriginalDelimiter > 3) {
      const indexOfThousandDelimiter = indexOfOriginalDelimiter - 3;
      amount = amount.substr(0, indexOfThousandDelimiter) + numberFormat.thousandDelimiter + amount.substr(indexOfThousandDelimiter);
    }
    return amount;
  }

  private formatDecimalDelimiter(amount: string, numberFormat: NumberFormat): string {
    return amount.replace('.', numberFormat.decimalDelimiter);
  }

  private formatDecimalDigits(amount: string, numberFormat: NumberFormat): string {
    const indexOfOriginalDelimiter = amount.indexOf('.');
    if (indexOfOriginalDelimiter >= 0) {
      let decimalDigits = amount.substr(indexOfOriginalDelimiter + 1);

      if (decimalDigits.length > numberFormat.decimalDigits) {
        decimalDigits = decimalDigits.substr(0, numberFormat.decimalDigits);
      }

      if (decimalDigits.length < numberFormat.decimalDigits) {
        decimalDigits = this.addMissingDigits(decimalDigits, numberFormat);
      }

      return amount.substr(0, indexOfOriginalDelimiter + 1) + decimalDigits;
    } else {
      const decimalDigits = this.addMissingDigits('', numberFormat);
      return amount + numberFormat.decimalDelimiter + decimalDigits;
    }
  }

  private addMissingDigits(decimalDigits: string, numberFormat: NumberFormat): string {
    const missingDigits = numberFormat.decimalDigits - decimalDigits.length;
    for (let index = 0; index < missingDigits; index++) {
      decimalDigits += '0';
    }
    return decimalDigits;
  }
}
