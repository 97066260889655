import { HttpClient } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

export class FormServiceConfig {
  default = '';
}
@Injectable({
  providedIn: 'root',
})
export class FormService {
  configuration: any = {};
  isocode: string | undefined = 'default';
  constructor(
    private http: HttpClient,
    @Optional() config?: FormServiceConfig
  ) {
    this.isocode = config?.default;
  }

  async init(http: HttpClient) {
    let country = this.isocode?.split('-')[1].toLocaleLowerCase();
    await this.fetchData(http, country);
  }

  getConfig(key: string) {
    if (this.configuration != undefined) {
      let value: any;
      if (key.includes('.')) {
        let splitKey = key.split('.');
        let depth: number = splitKey.length - 1;
        try {
          if (depth == 1) {
            value = this.configuration[splitKey[0]][splitKey[1]];
          } else if (depth == 2) {
            value = this.configuration[splitKey[0]][splitKey[1]][splitKey[2]];
          } else if (depth == 3) {
            value =
              this.configuration[splitKey[0]][splitKey[1]][splitKey[2]][
              splitKey[3]
              ];
          } else if (depth == 4) {
            value =
              this.configuration[splitKey[0]][splitKey[1]][splitKey[2]][
              splitKey[3]
              ][splitKey[4]];
          } else if (depth == 5) {
            value =
              this.configuration[splitKey[0]][splitKey[1]][splitKey[2]][
              splitKey[3]
              ][splitKey[4]][splitKey[5]];
          }
        } catch (x) {
          return false;
        }
      } else {
        value =
          this.configuration[key] === undefined
            ? false
            : this.configuration[key];
      }
      return value;
    } else {
      return false;
    }
  }

  async use(isocode: string) {
    this.isocode = isocode;
    let country = isocode?.split('-')[1].toLocaleLowerCase();
    await this.fetchData(this.http, country);
  }

  async fetchData(http: HttpClient, country: string | undefined) {
    var keys: string[] = [
      'address-commercial',
      'address-private',
      'address-display-commercial',
      'address-display-private',
      'userdata-commercial',
      'userdata-private',
    ];
    for (var key of keys) {
      let tmpUrl =
        environment.configuration +
        '/validation/' +
        country +
        '/' +
        key +
        '.json';
      let tmpResult: any = {};
      try {
        tmpResult = await firstValueFrom(http.get(tmpUrl));
      } catch (ex) {
        tmpUrl =
          environment.configuration + '/validation/default/' + key + '.json';
        tmpResult = await firstValueFrom(http.get(tmpUrl));
      }
      this.configuration[key] = {};
      this.configuration[key] = tmpResult;
    }
  }
}
