  <div class="container navigation-element-container">
    <div class="row align-items-center navigation-element-container-item">
      <div class="navigation-icon-box">
        <div class="navigation-icon">
          <img src={{image}} alt={{header}}>
        </div>
      </div>
      <div class="col-80 col-lg-65 my-3">
        <h3 class="navigation-element-header">{{header}}</h3>
        <p class="navigation-element-text">{{text}}</p>
      </div>
    </div>
  </div>
