import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'partnumber',
})
export class PartnumberPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return this.format(value, '#.###-###.#');
  }

  private format(value: any, pattern: any) {
    let i = 0;
    const v = value.toString();
    return pattern.replace(/#/g, (_) => v[i++]);
  }
}
