import { FeatureService } from '../../modules/feature/feature.service';
import { CurrencyConfiguration } from '../../models/currency-configuration.model';
import { Component, Input, OnInit } from '@angular/core';
import { CultureSetting } from '../../models/culture-setting.model';
import { Price } from '../../models/price.model';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.css'],
})
export class CurrencyComponent implements OnInit {
  @Input() isocode: string;
  @Input() amount: string;
  cultureSetting: CultureSetting;
  price: Price;
  constructor(private feature: FeatureService) {}
  ngOnInit(): void {
    this.cultureSetting = {
      code: this.isocode,
      currencyFormat: this.feature.activated('currencyFormat'),
      numberFormat: this.feature.activated('numberFormat'),
      percentageFormat: { decimalDigits: 2, decimalDelimiter: ',' },
      language: this.feature.activated('lang')[0],
    };
    this.price = {
      amount: this.amount,
      currency: this.cultureSetting.currencyFormat.code,
    };
  }
}
