import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { RouterParamService } from 'src/app/shared/params/params.service';

@Component({
  selector: 'app-info-header',
  templateUrl: './info-header.component.html',
  styleUrls: ['./info-header.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class InfoHeaderComponent implements OnInit {
  header: string = '';
  text: string = '';
  isocode: string = '';
  back: boolean = false;
  backUrl: string = 'navigation';
  currentRoute: string = '';
  urlArray: string[];
  id: string = '';
  skelleton: boolean = false;
  oldPath: string;
  constructor(
    private router: Router,
    private location: Location,
    public params: RouterParamService
  ) {}
  ngOnInit(): void {
    this.router.events.subscribe(() => {
      this.setValues();
    });

    this.setValues();
  }
  backPath() {
    if (this.backUrl === 'navigation') {
      this.router.navigate([this.isocode]);
    } else {
      this.router.navigate([this.isocode, this.backUrl]);
    }
  }
  setValues() {
    if (this.location.path() != '') {
      //Check if something changed
      if (this.oldPath != this.location.path()) {
        this.oldPath = this.location.path();
        this.urlArray = this.location.path().split('/');
        if (this.urlArray.length == 3) {
          this.currentRoute = this.urlArray[2].split('?')[0];
          this.skelleton = false;
          this.back = true;
          this.backUrl = 'navigation';
        } else if (this.urlArray.length == 2) {
          this.currentRoute = this.urlArray[1].split('?')[0];
          if (this.currentRoute == 'callback') {
            this.skelleton = true;
          } else {
            this.skelleton = false;
            this.currentRoute = 'navigation';
          }
          this.back = false;
        } else {
          this.skelleton = false;
          this.currentRoute =
            this.urlArray[3].split('?')[0] + '.' + this.urlArray[2];
          this.back = true;
          this.backUrl = this.urlArray[2];
        }

        if (this.urlArray?.[2] == 'orders') {
          this.id = this.params.paramsSnapshot()['id'];
        }
      }
    } else {
      this.skelleton = true;
    }
    this.isocode = this.params.paramsSnapshot()['isocode'];
  }
}
