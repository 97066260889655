import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RouterParamService } from 'src/app/shared/params/params.service';

@Component({
  selector: 'app-navigation-container',
  templateUrl: './navigation-container.component.html',
  styleUrls: ['./navigation-container.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class NavigationContainerComponent implements OnInit {
  isocode: string = '';
  isAuthenticated = false;
  linkContact: string = 'contact.url';
  linkNewsLetter: string = 'newsletter.url';

  constructor(
    private router: Router,
    public params: RouterParamService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.isocode = this.params.paramsSnapshot()['isocode'];
  }

  changePath(path: string) {
    this.router.navigate([this.isocode, path]);
  }

  hasTranslation(key: string): boolean {
    const translation = this.translateService.instant(key);
    return translation !== key && translation !== '';
  }
}
