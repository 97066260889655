import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';

import { NavigationModule } from '../navigation/navigation.module';
import { CallbackComponent } from '../authoritzation/callback/callback.component';
import { UnauthorizedComponent } from '../authoritzation/unauthorized/unauthorized.component';
import { PageNotFoundComponent } from '../home/page-not-found/page-not-found.component';
import { RedirectRockComponent } from 'src/app/feature/home/redirect-rock/redirect-rock.component';
import { RedirectRegistrationComponent } from '../home/redirect-registration/redirect-registration.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'de-DE' },
  { path: 'callback', component: CallbackComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  {
    path: ':countrycode/Service/Repair/Show-Repair-Orders/order/:rockordernumber',
    component: RedirectRockComponent,
  },
  {
    path: ':countrycode/overview/:rockordernumber',
    component: RedirectRockComponent,
  },
  {
    path: ':countrycode/sign-up',
    component: RedirectRegistrationComponent,
  },
  {
    path: ':isocode/profile',
    loadChildren: () =>
      import('src/app/feature/profile/profile.module').then(
        (m) => m.ProfileModule
      ),
  },
  {
    path: ':isocode/address',
    loadChildren: () =>
      import('src/app/feature/address/address.module').then(
        (m) => m.AddressModule
      ),
  },
  {
    path: ':isocode/orders',
    loadChildren: () =>
      import('src/app/feature/orders/orders.module').then(
        (m) => m.OrdersModule
      ),
  },
  {
    path: ':isocode/service',
    loadChildren: () =>
      import('src/app/feature/rock/rock.module').then((m) => m.RockModule),
  },
  {
    path: ':isocode',
    loadChildren: () => NavigationModule,
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class RootingModule {}
