import { Component } from '@angular/core';

@Component({
  selector: 'app-redirect-rock',
  templateUrl: './redirect-rock.component.html',
  styleUrls: ['./redirect-rock.component.css'],
})
export class RedirectRockComponent {
  constructor() {}
}
