<div class="container px-3 px-xl-0 pb-5">
  <app-navigation-container />
</div>
<div *ngIf="('feature.recommendations' | feature)">
  <app-recommendation />
</div>

<div class="container px-lg-0" *ngIf="('hide.hgapp.apple' | feature) === false || ('hide.hgapp.google' | feature) === false">
  <app-advertisment [isocode]="isocode" />
</div>
