import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';

import { HomeComponent } from './home/home.component';
import { InfoHeaderComponent } from './info-header/info-header.component';
import { CommonModule } from '@angular/common';
import { RootingModule } from '../routing/routing.module';
import { AuthConfigModule } from '../authoritzation/auth-config.module';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { environment } from 'src/environments/environment';
import { RedirectComponent } from './redirect/redirect.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RedirectRockComponent } from './redirect-rock/redirect-rock.component';
import { FeatureModule } from 'src/app/shared/modules/feature/feature.module';
import { RedirectRegistrationComponent } from './redirect-registration/redirect-registration.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.translation + '/', '.json');
}

@NgModule({
  declarations: [
    HomeComponent,
    InfoHeaderComponent,
    RedirectComponent,
    PageNotFoundComponent,
    RedirectRockComponent,
    RedirectRegistrationComponent,
  ],
  imports: [
    CommonModule,
    AuthConfigModule,
    RootingModule,
    NgxSkeletonLoaderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en-GB',
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [HomeComponent],
  bootstrap: [HomeComponent],
})
export class HomeModule {}
