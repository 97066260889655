import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  booleanAttribute,
} from '@angular/core';
import { FormControl, FormControlSelect, FormRow } from '@kaercher/ng-forms';
import { TranslateService } from '@ngx-translate/core';
import { AddressModel } from 'src/app/feature/address/models/address.model';
import { FormService } from '../../modules/form/form.service';

@Component({
  selector: 'app-address-info',
  templateUrl: './address-info.component.html',
  styleUrls: ['./address-info.component.css'],
})
export class AddressInfoComponent implements OnChanges {
  @Input() address: AddressModel;
  @Input() addressDisplayConfigurationRows: FormRow[];

  displayRows: FormRow[] = [];
  constructor(public translate: TranslateService, private form: FormService) { }
  ngOnChanges(_: SimpleChanges): void {
    if (this.addressDisplayConfigurationRows && this.address) {
      this.displayRows = [];
      for (const formRow of this.addressDisplayConfigurationRows) {
        var displayRow = this.createDisplayRow(formRow);
        this.displayRows.push(displayRow);
      }
    }
  }

  private createDisplayRow(formRow: FormRow): FormRow {
    var displayRow = {
      controls: [],
    } as FormRow;

    for (const control of formRow.controls) {
      let value: string = '';

      switch (control.name) {
        case 'title':
        case 'academicTitle':
          value = this.selectTranslation(control);
          break;
        case 'region':
        case 'cities':
        case 'district':
          value = this.regionTranslation(control);
          break;
        default:
          value = this.mapValueFromEnteredAddress(control.name);
          break;
      }

      var displayControl = {
        name: control.name,
        value: value,
      } as FormControl;

      displayRow.controls.push(displayControl);
    }

    return displayRow;
  }
  private selectTranslation(control): string {
    let value = '';
    let fieldValue = this.mapValueFromEnteredAddress(
      control.name
    ).toLowerCase();
    if (fieldValue == 'none' || fieldValue == '') {
      value = '';
    } else {
      let translationKey: string =
        control.translationKey + '.option.' + fieldValue;
      value = this.translate.instant(translationKey) ?? this.mapValueFromEnteredAddress(control.name);
    }
    return value;
  }
  private regionTranslation(control): string {
    let configName: string = 'address-private';
    if (this.address.addressType == 'Commercial') {
      configName = 'address-commercial';
    }
    let fieldValue = this.mapValueFromEnteredAddress(control.name);
    let formConfiguration: any[] = this.form.getConfig(configName);
    let idValue: string = '';
    formConfiguration.forEach((row) => {
      row.controls.map((_control) => {
        if (_control.name == control.name) {
          let selectControl: FormControlSelect = _control;
          if (selectControl.options.length != 0) {
            selectControl.options.every((element) => {
              if (
                element.translationKey == undefined ||
                element.translationKey == ''
              ) {
                if (element.value == fieldValue) {
                  idValue = element.id;
                  return false;
                } else {
                  return true;
                }
              } else {
                idValue = this.translate.instant(element.translationKey);
                return false;
              }
            });
          }
        }
      });
    });
    return idValue;
  }

  private mapValueFromEnteredAddress(controlName: string): string {
    return this.address[controlName] ?? '';
  }
}
