import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyFormat } from '../models/currency-format.model';
import { NumberFormat } from '../models/number-format.model';
import { Price } from '../models/price.model';
import { NumberFormatPipe } from './number-format.pipe';

@Pipe({
  name: 'currencyFormat',
})
export class CurrencyFormatPipe implements PipeTransform {
  constructor(private numberFormatPipe: NumberFormatPipe) {}

  transform(price: Price, currencyFormat: CurrencyFormat): string {
    return this.formatCurrency(price, currencyFormat);
  }

  private formatCurrency(price: Price, currencyFormat: CurrencyFormat): string {
    if (!price || !currencyFormat) {
      return '';
    }
    const symbol =
      currencyFormat.code === price.currency
        ? currencyFormat.symbol
        : price.currency;
    const amountDisplayed = this.numberFormatPipe.transform(
      price.amount,
      new NumberFormat(
        currencyFormat.decimalDigits,
        currencyFormat.decimalDelimiter,
        currencyFormat.thousandDelimiter,
      ),
    );
    return currencyFormat.isSymbolBehind
      ? amountDisplayed + symbol
      : symbol + amountDisplayed;
  }
}
