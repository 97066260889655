import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FeatureService } from 'src/app/shared/modules/feature/feature.service';
import { RouterParamService } from 'src/app/shared/params/params.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css'],
})
export class PageNotFoundComponent {
  active: boolean;
  isocode: string;
  urlArray: string[];
  constructor(
    public params: RouterParamService,
    private router: Router,
    private location: Location
  ) {
    if (location.path() != '') {
      this.urlArray = location.path().split('/');
      this.isocode = this.urlArray[1];
      this.router.navigate([this.isocode]);
    }
  }
}
