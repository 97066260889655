
  <div *ngIf="loading; else elseLoadingBlock">
    <div class="bg-secondary">
      <!--DESKTOP VIEW-->
      <div class="container px-3 px-xl-0">
        <div class="row row row-cols-1 row-cols-lg-4 g-3">
          <ngx-skeleton-loader
            class="col"
            [theme]="{ height: '400px' }"
          ></ngx-skeleton-loader>
          <ngx-skeleton-loader
            class="col"
            [theme]="{ height: '400px' }"
          ></ngx-skeleton-loader>
          <ngx-skeleton-loader
            class="col"
            [theme]="{ height: '400px' }"
          ></ngx-skeleton-loader>
          <ngx-skeleton-loader
            class="col"
            [theme]="{ height: '400px' }"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
  <ng-template #elseLoadingBlock>
    <div *ngIf="recommendations.length > 0; else noRecommendation">
      <div class="container pb-4 px-3 px-xl-0">
        <p class="recommendation-header">
          {{ "recommendations.headline" | translate }}
        </p>
      </div>
      <!--DESKTOP VIEW-->
      <div class="bg-secondary">
        <div class="container px-3 px-xl-0">
          <div class="row d-none d-lg-flex row-cols-1 row-cols-lg-4 g-3">
            <div *ngFor="let recommendation of recommendations">
              <app-recommendation-element
                [recommendation]="recommendation"
                [isocode]="isocode"
              />
            </div>
          </div>
        </div>
      </div>
      <!--MOBILE VIEW-->
      <div class="container px-3 px-xl-0">
        <div class="row d-block d-lg-none row-cols-1  g-3">
          <div (touchstart)="posIni = $event.changedTouches[0].pageX"
               (touchend)="move($event.changedTouches[0].pageX)">
            <ngb-carousel #carousel class="no-indicators" activeId="1">
              <ng-template ngbSlide id="1">
                  <app-recommendation-element
                    [recommendation]="recommendations[0]"
                    [isocode]="isocode"
                  />
                </ng-template>
              <ng-template ngbSlide id="2">
                <app-recommendation-element
                  [recommendation]="recommendations[1]"
                  [isocode]="isocode"
                />
              </ng-template>
              <ng-template ngbSlide id="3">
                <app-recommendation-element
                  [recommendation]="recommendations[2]"
                  [isocode]="isocode"
                />
              </ng-template>
              <ng-template ngbSlide id="4">
                <app-recommendation-element
                  [recommendation]="recommendations[3]"
                  [isocode]="isocode"
                />
              </ng-template>
            </ngb-carousel>
          </div>
        </div>
      </div>

    </div>
    <ng-template #noRecommendation> </ng-template>
  </ng-template>
