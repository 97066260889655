import { NgModule } from '@angular/core';

//Serivces
import { NumberFormatPipe } from '../shared/pipes/number-format.pipe';
import { PercentageFormatPipe } from '../shared/pipes/percentage-format.pipe';

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    { provide: NumberFormatPipe, useClass: NumberFormatPipe },
    { provide: PercentageFormatPipe, useClass: PercentageFormatPipe },
  ],
})
export class CoreModule {}
