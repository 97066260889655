import { Pipe, PipeTransform } from '@angular/core';
import { NumberFormat } from '../models/number-format.model';
import { PercentageFormat } from '../models/percentage-format.model';
import { NumberFormatPipe } from './number-format.pipe';

@Pipe({
  name: 'percentageFormat',
})
export class PercentageFormatPipe implements PipeTransform {
  constructor(private numberFormatPipe: NumberFormatPipe) {}

  transform(value: any, args?: any): string {
    const percentageFormat = args as PercentageFormat;
    let percentageValue = this.numberFormatPipe.transform(
      value,
      new NumberFormat(percentageFormat.decimalDigits, percentageFormat.decimalDelimiter, '')
    );
    percentageValue = this.formatPercentage(value as number, percentageFormat);
    return percentageValue;
  }

  private formatPercentage(percentageValue: number, percentageFormat: PercentageFormat) {
    return percentageValue + '%';
  }
}
