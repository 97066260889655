import { Component } from '@angular/core';

@Component({
  selector: 'app-redirect-registration',
  templateUrl: './redirect-registration.component.html',
  styleUrls: ['./redirect-registration.component.css'],
})
export class RedirectRegistrationComponent {
  constructor() {}
}
