import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit, Optional } from '@angular/core';
import { Observer, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

export class FeatureServiceConfig {
  default = '';
}
@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  configuration: any;
  countrycode: string | undefined = 'default';
  constructor(
    private http: HttpClient,
    @Optional() config?: FeatureServiceConfig,
  ) {
    this.countrycode = config?.default;
  }

  async init() {
    let url =
      environment.configuration +
      '/configuration/' +
      this.countrycode +
      '.json';
    let result = await firstValueFrom(this.http.get(url));
    this.configuration = result;
  }

  activated(key: string) {
    if (this.configuration != undefined) {
      let value: any;
      if (key.includes('.')) {
        let splitKey = key.split('.');
        let depth: number = splitKey.length - 1;
        try {
          if (depth == 1) {
            value = this.configuration[splitKey[0]][splitKey[1]];
          } else if (depth == 2) {
            value = this.configuration[splitKey[0]][splitKey[1]][splitKey[2]];
          } else if (depth == 3) {
            value =
              this.configuration[splitKey[0]][splitKey[1]][splitKey[2]][
                splitKey[3]
              ];
          } else if (depth == 4) {
            value =
              this.configuration[splitKey[0]][splitKey[1]][splitKey[2]][
                splitKey[3]
              ][splitKey[4]];
          } else if (depth == 5) {
            value =
              this.configuration[splitKey[0]][splitKey[1]][splitKey[2]][
                splitKey[3]
              ][splitKey[4]][splitKey[5]];
          }
        } catch (x) {
          return false;
        }
      } else {
        value =
          this.configuration[key] === undefined
            ? false
            : this.configuration[key];
      }

      return value;
    } else {
      return false;
    }
  }

  async use(countrycode: string) {
    this.countrycode = countrycode;
    let url =
      environment.configuration + '/configuration/' + countrycode + '.json';
    let result = await firstValueFrom(this.http.get(url));
    this.configuration = result;
    return result;
  }
}
